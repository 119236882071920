.netronner-logo {
    width: 100%;
    height: 149px;
    background-image: url(./assets/tilesoffury-logo-240-149.png);
    background-repeat: no-repeat;
    background-position: 10px 0;    
}

#main-container {
    padding-bottom: 3rem;
    padding-top: .5rem;
}

span.icon {
    display: inline-block;
    background-repeat: no-repeat;
}
span.icon.i16 {
    background-size: 16px;
    margin-right: 8px;
    width: 16px;
    height: 16px;
}
span.icon.octocat {
    background-image: url(./assets/GitHub-Mark-32px.png);
}
span.icon.ema {
    background-image: url(./assets/logo_ema.png);
}

.achievement .icon {
    background-color: #398439;
}

.achievement p.flavour-text {
    font-style: italic;
}

nav.bg-green {
    background-color: #3c9f3c;
}

nav.bg-green.nav-pills .nav-link.active {
    background-color: #15682a;
    color: #000;
}
nav.bg-green.nav-pills .nav-link p {
  margin-bottom: 0;
}
nav.bg-green.nav-pills .nav-link p:nth-child(2) {
  font-size: .55rem;
  color: #15682a;
}
/* TODO: firefox devtools have an inspection that tell you about contrast and this value is bad, review the palette*/
nav.bg-green.nav-pills .nav-link.active p:nth-child(2) {
  color: #3c9f3c;
}

div.score-output {
    border: 2px dashed #3c9f3c;
    border-radius: .5rem;
    padding: 2rem;
    text-align: center;
}
div.btn-group.btn-group-toggle {
    margin-bottom: 1rem;
}

.game-item .timestamp {
    font-style: italic;
    color: grey;
}

span.round-indicator {
    background-color: orangered;
    border-radius: .5rem;
    color: black;
    display: inline-block;
    font-size: 1.5rem;
    font-weight: bolder;
    margin: .5rem;
    padding: .5rem 3rem;
    text-align: center;
}

h1.hand-indicator {
    display: inline-block;
}

span.player-score-texticon {
    align-items: center;
    border-radius: 50%;
    display: flex;
    font-size: 1.25rem;
    height: 40px;
    justify-content: center;
    text-align: center;
    width: 40px;
    margin: .5rem;
}

span.player-score-texticon.lightblue {
    background-color: lightblue;
}
span.player-score-texticon.gold {
    background-color: gold;
}
span.player-score-texticon.magenta {
    background-color: magenta;
}
span.player-score-texticon.lightsalmon {
    background-color: lightsalmon;
}

.game-scoring button.col {
    margin: .25rem;
}
.game-scoring button.col:nth-of-type(2n) {
    margin-right: .5rem;
}
.game-scoring button.col:nth-of-type(2n + 1) {
    margin-left: .5rem;
}
.game-scoring div.media.highlight-green {
    background-color: #93d286;
}
.game-scoring div.media.highlight-grey {
    background-color: #a7abae;
}
.game-scoring select.row {
    margin-left: .5rem;
    margin-right: .5rem;
}
.game-scoring .player-score button.disabled {
    cursor: pointer; /* in this case we're using disabled for graphics but it's rather "toggled off" and we still want pointer */
}
.game-scoring .player-score h5.editable {
    cursor: pointer;
}
.game-scoring .editing {
    display: flex;
}
.game-scoring .editing input {
    flex: 1;
}
.game-scoring .editing button {
}